<template>
  <div>
    <b-overlay
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height">
          <transition
          mode="out-in"
          >
          <b-col md="12">
            <b-card>
                <b-form @submit.prevent="create">
                  <b-row>

                    <!-- Name -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Name')"
                        label-for="v-name"
                      >
                        <b-form-input
                          id="v-name"
                          :placeholder="$t('Name')"
                          v-model="form.name"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Districts -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Districts')"
                        label-for="v-districts"
                      >
                        <v-select
                          v-model="form.districts_ids"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          label="nameWithCity"
                          :options="districts"
                          :reduce="district => district.id"
                          multiple
                        />
                        <b-form-text>{{$t('You can select many district for one zone')}}</b-form-text>
                      </b-form-group>
                    </b-col>

                    <!-- Users -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Delivery men')"
                        label-for="v-delivery-men"
                      >
                        <v-select
                          v-model="form.users_ids"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          label="email"
                          :options="users"
                          :reduce="user => user.id"
                          multiple
                        />
                        <b-form-text>{{$t('You can select many delivery man for one zone')}}</b-form-text>
                      </b-form-group>
                    </b-col>
                    
                    <!-- submit -->
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                      >
                        <template v-if="!isLoadingSave">
                          {{$t('Save')}}
                        </template>
                        <template v-if="isLoadingSave">
                          {{$t('Saveing')}}...
                          <b-spinner small />
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
            </b-card>
          </b-col>
          </transition>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  directives: {
    Ripple,
  },
  components: {
    quillEditor,
  },
  name: 'ShippingZoneCreate',

  data() {
    return {
      isLoading: false,
      isLoadingSave: false,
      form:{
        name: null,
        districts_ids: [],
        users_ids: [],
      },
      snowOption: {},
      districts: [],
      users: [],
    }
  },
  mounted() {
    this.getDistricts();
    this.getUsers();
  },
  methods: {
    create() {
      this.isLoadingSave = true;
      useJwt.post('/shipping-zones/store',this.form)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        this.$router.push({ name: 'shipping-zones' })
      })
      .catch(error => {
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message.title)
      });
    },
    getDistricts(){
      useJwt.get('/districts/get-all')
      .then((response) => {
        // console.log(response.data)
        this.districts = response.data.data
      })
      .catch(response => {
        // console.log(response);
      });
    },
    getUsers(){
      useJwt.post('/users/get-all',{store_id: this.$store.state.store.id})
      .then((response) => {
        // console.log(response.data)
        this.users = response.data.data.users
      })
      .catch(response => {
        // console.log(response);
      });
    },
  }
}
</script>
<style lang="scss">
.ql-editor {
  height: 250px;
}
</style>
